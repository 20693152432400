import React from 'react';
import wifi from './images/wifi.png';
import tv from './images/tv.png';
import tvnet from './images/tv-net.png';

const Main = () => {
    return (
        <main className="main">
            <OfferBox price="3990"
                      list={internetData} 
                      link="https://www.netsurfclub.hu/ajanlatkeres.php" 
                      service="Internet" 
                      package="AirForce" 
                      src={wifi} 
                      alt="wifi-logó" />

            <OfferBox price="3490"
                      list={tvData} 
                      link="https://www.netsurfclub.hu/ajanlatkeres.php" 
                      service="TV" 
                      package="JóLegyen!" 
                      src={tv} 
                      alt="tv-logó" />

            <OfferBox price="7480"
                      list={internetTvData} 
                      link="https://www.netsurfclub.hu/ajanlatkeres.php" 
                      service="TV + Internet" 
                      package="Kettő az egyben" 
                      src={tvnet} 
                      alt="wifi-logó" />           
        </main>
    );
}

const OfferBox = (props) => {
    return (
        <div className="offer-box">
            <OfferBoxHeader service={props.service} package={props.package} src={props.src} alt={props.alt} />
            <OfferBoxList list={props.list} />
            <OfferBoxPrice price={props.price} />
            <OfferButton link={props.link} />
        </div>
    );
}

const OfferBoxHeader = (props) => {
    return (
        <div className="offer-box-header">
            <h3>{props.service}</h3>
            <h2>{props.package}</h2>
            <img src={props.src} alt={props.alt} />
        </div>
    );
}

const OfferBoxList = (props) => {
    const list = props.list;
    const listItems = list.map( (item, index) =>
        <li className="offer-box-list-item" key={index}>
            <div className="offer-box-pipe">
                <i class="fas fa-check"></i>
            </div>
            <div className="offer-box-text">
                {item}
            </div>
        </li>
    );

    return (
        <ul className="offer-box-list">{listItems}</ul>
    );
}

const OfferBoxPrice = (props) => {
    return (
        <div className="offer-box-price">
            {props.price} Ft 
            <span> | hó-tól</span>
        </div>
    );
}

const OfferButton = (props) => {
    return (
        <div className="offer-box-button">
            <a className="offer-button" href={props.link} target="_blank" rel="noreferrer">Érdekel</a>
        </div>
    );
}

const internetData = [
    '30-150 Mbps sebesség',
    'Korlátlan adatmennyiség',
    'Vezetékes hálózat nélkül',
    'Hűségmentesen'
];

const tvData = [
    '52 - 100 csatorna',
    'Saját csomag összeállítás választható csatornákkal',
    'Magaddal viheted bárhova az országban',
    'A mobilodon is nézheted ;)'
];

const internetTvData = [
    'Akár 80 SD + 20 HD csatorna',
    '150/60 Mbps korlátlan internet',
    'Vezetékes hálózat nélkül',
    'Hűségmentes'
];

export default Main;